<script setup lang="ts">
import { getMyOrganization, getTaskStatusFromAttendant } from "../../helpers";

definePage({
  meta: {
    title: "Atendentes",
    icon: "users-three",
  },
});

const userStore = useStore("user");
const taskStore = useStore("task");

const selectedAttendant = ref<any>(null);
const modal = ref(false);
const users = ref<any[]>([]);
const tasks = ref([]);

const columns = {
  name: {},
  status: {},
};

onMounted(async () => {
  const { result: organization } = await getMyOrganization(userStore.currentUser);
  users.value = organization.members.map((member: any) => member.user);
});

const formatTaskName = (task: any) =>
  task.type == "postback"
    ? `Postback: ${task.event} ${task.order.customer.name} (${task.order.products[0].name})`
    : `Broadcast: ${task.broadcast.name}`;

const openAttendantDetails = async (attendant: any) => {
  const { result } = await aeria.task.getTaskFromAttendants.POST({
    attendantId: attendant._id,
  });

  tasks.value = result.map((task: any) => ({
    name: formatTaskName(task),
    status: getTaskStatusFromAttendant(task, attendant._id),
  }));

  selectedAttendant.value = attendant;
  modal.value = true;
};
</script>

<template>
  <aeria-table :key="users.length">
    <template #thead>
      <tr>
        <th>Nome completo</th>
        <th>Email</th>
        <th>Ativo</th>
        <th>Ações</th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="user in users" :key="user._id">
        <td>{{ user.name }}</td>
        <td>{{ user.email }}</td>
        <td>
          <status-badge :color="user.active ? 'green' : 'blue'">
            {{ user.active ? "Sim" : "Não" }}
          </status-badge>
        </td>
        <td>
          <aeria-context-menu
            v-bind="{
              actions: [
                {
                  icon: 'align-left',
                  label: 'Detalhes',
                  click: () => openAttendantDetails(user),
                },
              ],
            }"
          >
            <aeria-icon
              v-clickable
              reactive
              icon="dots-three-vertical"
            ></aeria-icon>
          </aeria-context-menu>
        </td>
      </tr>
    </template>
  </aeria-table>

  <aeria-panel
    close-hint
    fixed-right
    title="Detalhes"
    v-model="modal"
    @overlay-click="modal = false"
  >
    <h3>Atendente</h3>
    <div class="tw-space-y-2 tw-text-md">
      <aeria-icon icon="smile">{{ selectedAttendant.name }}</aeria-icon>
      <aeria-icon icon="envelope">{{ selectedAttendant.email }}</aeria-icon>
    </div>

    <h3>Tarefas</h3>
    <aeria-table translate :columns="columns" :rows="tasks"></aeria-table>
  </aeria-panel>
</template>
