<script setup lang="ts">
import FlowModal from "./../../components/task/flow-modal.vue";
import ExecutionsModal from "./../../components/task/executions-modal.vue";
import { notNull } from "../../helpers";
import { useI18n } from "aeria-ui";
const { t } = useI18n()

definePage({
  meta: {
    title: "Tarefas",
    icon: "list",
    collection: "task",
  },
});

const taskStore = useStore("task");
const metaStore = useStore("meta");
const flowStore = useStore("flow");
const userStore = useStore("user");

const timer = ref<NodeJS.Timeout | null>(null);
const funnelView = ref(false);
const executionsView = ref(false);

const attendentId = computed(() => userStore.currentUser._id);

onMounted(async () => {
  timer.value = setInterval(fetchTasks, 10000);
  fetchTasks();
});

onUnmounted(() => clearInterval(timer.value as NodeJS.Timeout));

const fetchTasks = () =>
  taskStore.$actions.getAll();

const openFunnel = async (task: any) => {
  flowStore.$actions.importMessages(task.funnel.messages || []);
  funnelView.value = true;
};

const openExecutions = async (task: any) => {
  const { result: executions } = await aeria.task.getExecutions.POST({
    taskId: task._id,
  });

  
  taskStore.$actions.setItem({ ...task, executions });
  
  executionsView.value = true;
};

const executePause = async (task: any) => {
  const { error } = await aeria.task.executePause.POST({
    taskId: task._id,
  });
  
  if (error) {
    metaStore.$actions.spawnModal({
      title: "Ops",
      body: error.message,
    });

    return
  }

  await fetchTasks();

  metaStore.$actions.spawnToast({
    text: "A tarefa foi iniciada",
  });
};

const executeTask = async (task: any) => {
  const { error } = await aeria.task.executeTask.POST({
    taskId: task._id,
  });

  if (error) {
    metaStore.$actions.spawnModal({
      title: "Ops...",
      body: error.message ?? t(error.code, { capitalize: true }),
    });

    return
  }

  await fetchTasks();

  metaStore.$actions.spawnToast({
    text: "A tarefa foi iniciada",
  });
};

const pauseTask = async (task: any) => {
  const { error } = await aeria.task.pauseTask.POST({
    taskId: task._id,
  });

  if (error) {
    metaStore.$actions.spawnModal({
      title: "Ops",
      body: error.message,
    });
    return
  }

  await fetchTasks();

  metaStore.$actions.spawnToast({
    text: "A tarefa foi pausada",
  });
};

const onAction = (action: string, task: any) => {
  const actions = {
    showExecutions: openExecutions,
    showFunnel: openFunnel,
    execute: executeTask,
    executePause: executePause,
    pause: pauseTask,
  };

  return actions[action as keyof typeof actions](task);
};
</script>

<template>
  <aeria-crud collection="task" no-fetch>
    <template #actions>
      <automatic-task />
    </template>

    <template #component>
      <aeria-table v-if="taskStore.items.length">
        <template #thead>
          <tr>
            <th>Criado há</th>
            <th>Criado por</th>
            <th>Tarefa</th>
            <th>Status</th>
            <th></th>
          </tr>
        </template>
        <template #tbody>
          <tr v-for="task in taskStore.items" :key="task._id.toString()">
            <td>
              {{
                task.created_at &&
                getRelativeTimeFromNow(new Date(task.created_at))
              }}
            </td>
            <td>
              <div v-if="!notNull(task.owner)">@sistema</div>
              <user-quote v-else :user="task.owner"></user-quote>
            </td>
            <td>
              <div class="tw-flex tw-flex-col tw-gap-1">
                <div>
                  {{
                    task.type === "postback"
                      ? task.order?.customer?.name
                      : task.broadcast?.name
                  }}
                </div>
                <div class="tw-flex tw-items-center tw-gap-1">
                  <div class="tw-text-[9pt] tw-opacity-80">
                    {{
                      task.type === "postback"
                        ? (task?.order?.products?.length ?? 0) > 0
                          ? task?.order?.products[0].name
                          : "-"
                        : "Broadcast"
                    }}
                  </div>
                </div>
              </div>
            </td>
            <td>
              <task-badge :attendentId="attendentId" :task="task" />
            </td>
            <td>
              <task-table-actions :attendentId="attendentId" :task="task"
                @action="(action: string) => onAction(action, task)" />
            </td>
          </tr>
        </template>
      </aeria-table>

      <empty-component v-else></empty-component>
    </template>
  </aeria-crud>

  <executions-modal :task="taskStore.item" v-model="executionsView" @on-close="executionsView = false" />

  <flow-modal v-model="funnelView" :messages="flowStore.flow" @on-close="funnelView = false" />
</template>
