import { ref } from 'vue'
import { useApp, useStore, defineOptions } from 'aeria-ui'
import aeriaPtbr from '@aeria-ui/i18n-pt'

import '@aeria-ui/ui/style.css'
import 'aeria-app-layout/style.css'
import './style/main.less'
import './style/main.css'

import * as stores from './stores/index.js'
import { routes } from './routes.js'
import { ptbr } from './i18n'
import { getMyOrganization } from './helpers/index.js'
import Main from './main.vue'
import DashboardLayout from './pages/dashboard.vue'
import EmptyComponent from './components/empty-component.vue'
import PlatformSelect from './components/platform-select.vue'

const options = defineOptions({
  routes,
  dashboardComponent: DashboardLayout,
  component: Main,
  setup: ({ context }) => {
    Object.values(stores).map((registerStore) => {
      return registerStore(context)
    })

    Object.assign(useStore('user', context.manager).$actions, {
      signout() {
        localStorage.removeItem('aeria:previous-auth')
        localStorage.removeItem('aeria:auth')
        useStore('user', context.manager).$actions.setCurrentUser({})
      },
    })
  },
  i18n: {
    current: 'pt_BR',
    locales: {
      pt_BR: [
        aeriaPtbr,
        ptbr,
      ],
    },
  },
  menuSchema: [
    {
      roles: [
        'root', // só pra facilitar no ambiente de dev
        'customer',
      ],
      children: [
        '/dashboard/',
        '/dashboard/chats',
        '/dashboard/order',
        '/dashboard/task',
        '/dashboard/campaign',
        '/dashboard/settings/',
      ],
    },
    {
      meta: {
        title: 'Criação',
        icon: 'lightbulb',
      },
      collapsed: true,
      children: [
        '/creation/funnel',
        '/dashboard/broadcast/',
        '/creation/external-messages',
      ],
    },
    {
      roles: async () => {
        const userStore = useStore('user')
        if (!userStore.currentUser._id) {
          return true
        }

        const { result: currentOrganization } = await getMyOrganization(userStore.currentUser)
        return currentOrganization && currentOrganization.me.roles
          ? currentOrganization.me.roles.includes('manager')
          : false
      },
      children: [
        '/dashboard/attendants',
        '/dashboard/conversationTag',
        '/dashboard/integration',
        '/dashboard/my-organization/',
        '/dashboard/subscriptions',
      ],
    },
    {
      roles: ['root'],
      children: [
        '/dashboard/organizations',
        '/dashboard/user/',
      ],
    },
  ],
})

useApp(options).then(({ app, mount }) => {
  const exceptionModal = ref(false)
  const exceptionModalMessage = ref('')
  app.provide('emptyComponent', EmptyComponent)
  app.provide('exceptionModal', exceptionModal)
  app.provide('exceptionModalMessage', exceptionModalMessage)

  app.provide('propertyComponents', {
    PlatformSelect,
  })

  app.config.errorHandler = (err: any) => {
    exceptionModal.value = true
    exceptionModalMessage.value = err.message
    console.trace(err)
  }

  mount()
})
