<script setup lang="ts">
import { onBeforeUnmount } from 'vue'
import QRCode from 'qrcode'
import { formatPhoneNumber } from '../../../helpers'

definePage({
  meta: {
    title: 'Conectar aparelho',
    icon: 'whatsapp-logo',
  },
})

defineProps<{
  section: string
}>()

type Status =
  | 'UNPAIRED'
  | 'CONNECTED'
  | 'ERROR'
  | 'LOADING'

const userStore = useStore('user')
const router = useRouter()

const qrcode = ref()
const contact = ref({
  name: '',
  status: '',
  phone: '',
  picture: '',
})
const status = ref<Status>('LOADING')
const userId = userStore.currentUser._id

const socket = new WebSocket(import.meta.env.VITE_WS_URL)

watch(status, async (value) => {
  if (value === 'CONNECTED') {
    const { error, result } = await aeria.user.wppContact.POST()
    if (error) {
      console.error(error)
      return
    }

    contact.value = result
  }
})

onMounted(async () => {
  socket.onmessage = function (event) {
    const { type, data } = decodeData(event.data)

    if (type === 'qrcode') {
      status.value = 'UNPAIRED'
      setTimeout(() => {
        QRCode.toCanvas(qrcode.value, data.qrcode, function (error) {
          if (error) console.error(error)
        })
      }, 2000)
    }

    if (type === 'connected') {
      status.value = 'CONNECTED'
    }

    if (type === 'timeout') {
      status.value = 'ERROR'
    }
  }

  socket.onopen = function (_event) {
    this.send(encodeData('qrcode', userId))
  }
})

onBeforeUnmount(() => {
  socket.close()
})

const encodeData = (type: string, data: any) => JSON.stringify({ type, data })

const decodeData = (data: any) => JSON.parse(data)

const statusColor = (status?: Status) => {
  switch (status) {
    case 'ERROR':
    case 'UNPAIRED':
      return 'red'
    case 'CONNECTED':
      return 'green'
    case 'LOADING':
      return 'blue'
    default:
      return 'blue'
  }
}

const translateStatus = (status: Status) => {
  switch (status) {
    case 'ERROR':
      return 'Ops, ocorreu um erro'
    case 'UNPAIRED':
      return 'Não conectado'
    case 'CONNECTED':
      return 'Conectado'
    case 'LOADING':
      return 'Carregando...'
    default:
      return 'Ops, ocorreu um erro'
  }
}

const disconnectWpp = async () => {
  await aeria.user.wppLogout.POST()
  router.go(0)
}
</script>

<template>
  <div
    v-if="section === 'whatsapp' || !section"
    class="lg:tw-flex tw-justify-between"
  >
    <div class="tw-flex tw-flex-col tw-min-w-[24rem]">
      <table class="tw-table-auto tw-border-2 tw-border-spacing-4">
        <tr>
          <td>Status:</td>
          <td>
            <status-badge :color="statusColor(status)">
              {{ translateStatus(status) }}
            </status-badge>
          </td>
        </tr>
        <tr v-if="status === 'CONNECTED'">
          <td>Nome:</td>
          <td>{{ contact.name || '-' }}</td>
        </tr>
        <tr v-if="status === 'CONNECTED'">
          <td>Status do perfil:</td>
          <td>{{ contact.status || '-' }}</td>
        </tr>
        <tr v-if="status === 'CONNECTED'">
          <td>Telefone:</td>
          <td>{{ formatPhoneNumber(contact.phone) }}</td>
        </tr>
        <tr v-if="status === 'CONNECTED'">
          <td>Foto de perfil:</td>
          <td>
            <img
              class="tw-h-12 tw-rounded-full"
              :src="
                contact.picture != '' ? contact.picture : '/avatar.jpg'
              "
            />
          </td>
        </tr>
      </table>

      <div class="tw-mt-2" v-if="status === 'CONNECTED'">
        <div></div>
        <div>
          <aeria-button variant="alt" @click="disconnectWpp">
            Desconectar aparelho
          </aeria-button>
        </div>
      </div>

      <div class="tw-mt-10 tw-flex tw-flex-col"v-if="status === 'UNPAIRED'">
        <h3>Qr Code</h3>
        <div class="tw-border-2 tw-border-black tw-w-max">
          <canvas ref="qrcode"></canvas>
        </div>
      </div>
    </div>

    <div class="tw-flex tw-flex-1 tw-justify-center">
      <img src="/static/qr-code.webp" />
    </div>
  </div>

  <div v-else>On the making...</div>
</template>
